export default {
	API_URL: '/msp/public/count-aggregator/wheel-counter/',
	LIST_URL: '/geojson/region-hannover-radzaehler.geojson',
	STR_SERIES: 'Fahrradfahrende',
	DEFAULT_NUMBER_OF_STATIONS: 4, // Wieviele Stationen sollen auf Detailseite automatisch gezeigt werden?
	EARLIEST_YEAR: 2017,
	HIGHCHARTS_OPTIONS: {
		lang: {
			months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
			shortMonths: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
			weekdays: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
			numericSymbols: false, //[' tsd.', 'M', 'G', 'T', 'P', 'E'],
			printButtonTitle: 'Diagramm ausdrucken',
			resetZoomTitle: 'Zoom zurücksetzen',
			resetZoom: 'Zoom zurücksetzen',
			loading: 'Lädt...',
			exportButtonTitle: 'Diagramm sichern',
			downloadSVG: 'Als SVG sichern',
			downloadPNG: 'Als PNG sichern',
			downloadPDF: 'Als PDF sichern',
			downloadJPEG: 'Als JPEG sichern',
			decimalPoint: ',',
			thousandsSep: '.',
		},

		colors: ['#7cb5ec', '#434348', '#90ed7d', '#f7a35c', '#8085e9', '#f15c80', '#e4d354', '#2b908f', '#f45b5b', '#91e8e1'],

		credits: {
			enabled: false,
			href: 'https://neonaut.de/',
			text: 'Neonaut GmbH',
		},

		tooltip: {
			shared: true,
			xDateFormat: '%A, %d.%m.%Y',
			borderWidth: 1,
			borderRadius: 3,
			backgroundColor: 'rgba(250, 250, 250, 0.9)',
		},

		title: false,
		subtitle: false,

		xAxis: {
			type: 'datetime',
			dateTimeLabelFormats: {
				day: '%e. %b',
				month: '%b \'%y',
			},
		},

		yAxis: {
			type: 'linear',
			min: 0,
			title: false,
		},

		chart: {
			type: 'column',
			height: 560,
			backgroundColor: 'transparent',
			style: {
				fontFamily: 'HKGrotesk,HelveticaNeue-Light,Helvetica Neue Light,Helvetica Neue,Helvetica,Helvetica Now,HelveticaNow,FreeSans,Nimbus Sans,Nimbus Sans L,Liberation Sans,Arial,Lucida Grande,sans-serif',
				fontSize: '13px',
			},
		},

		plotOptions: {
			column: {
				pointPadding: 0.1,
				groupPadding: 0.1,
				style: 'padding: 0.1em',
			},
			area: {
				stacking: 'normal',
			},
		},

		exporting: {
			buttons: {
				enabled: true,
			},
		},
	},

	resolutions: {
		hourly: {value: 'hourly', label: 'Stunde'},
		daily: {value: 'daily', label: 'Tag'},
		weekly: {value: 'weekly', label: 'Woche'},
		monthly: {value: 'monthly', label: 'Monat'},
		yearly: {value: 'yearly', label: 'Jahr'},
	},
};
